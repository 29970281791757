import styled from "styled-components/macro";

import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as base from "../settings";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { useGoogleMaps } from "react-hook-google-maps";
import axios from "axios";

const Contact = (props) => {
  const [t] = useTranslation("common");
  const { ref, map, google } = useGoogleMaps(
    // Use your own API key, you can get one from Google (https://console.cloud.google.com/google/maps-apis/overview)
    "AIzaSyAzdh7lxduLdiOgSXoJ15X2HMKI4pHpJoA",
    // NOTE: even if you change options later
    {
      center: { lat: 11.437175031662637, lng: 75.9027895727252 },
      zoom: 2,
      disableDefaultUI: true,

      // gestureHandling: "cooperative",
      styles: [
        { elementType: "geometry", stylers: [{ color: "#121e2c" }] },
        {
          elementType: "labels.text.stroke",
          stylers: [{ color: "#000" }],
        },
        {
          elementType: "labels.text.fill",
          stylers: [{ color: "#846950" }],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#846950", fontWeight: "bold" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#846950" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#162b2e" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#162b2e" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#252f3c" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "none" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#252f3c" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#5e5547" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "none" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#5e5547" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#121e2c" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#846950" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#07162d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#07162d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "none" }],
        },
      ],
      zoomControl: false,
      scaleControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
    }
  );
  const image = "/images/position-marker.png";
  if (map) {
    // execute when map object is ready
    new google.maps.Marker({
      position: { lat: 11.4372519364231, lng: 75.90252101652672 },
      map,
      icon: image,
      title: "Vikncodes",

      label: {
        // text: "India",
        color: "#fff",
        fontSize: "18px",
      },
    });
  }
  if (map) {
    // execute when map object is ready
    new google.maps.Marker({
      position: { lat: 16.89807838743385, lng: 42.575132860104524 },
      map,
      icon: image,
      title: "Vikncodes",

      label: {
        // text: "Saudi Arabia",
        color: "#fff",
        fontSize: "18px",
      },
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, setState] = useState({
    name: "",
    message: "",
    phone: "",
    email: "",
    errors: {
      name: "",
      message: "",
      phone: "",
      email: "",
    },
  });
  console.log(state,'stateeee');
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //Name
    if (!state.name) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    } else if (typeof state.name !== "undefined") {
      if (!state.name.match(/^[a-zA-Z\s]*$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    //Message
    if (!state.message) {
      formIsValid = false;
      errors["message"] = "Cannot be empty";
    }
    //Subject
    if (!state.subject) {
      formIsValid = false;
      errors["subject"] = "Cannot be empty";
    }

    //Phone
    if (!state.phone) {
      formIsValid = false;
      errors["phone"] = "Cannot be empty";
    } else if (typeof state.phone !== "undefined") {
      if (state.phone.length < 10) {
        formIsValid = false;
        errors["phone"] = "Please enter a valid phone number";
      }
    }

    //Email
    if (!state.email) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    } else if (typeof state.email !== "undefined") {
      let lastAtPos = state.email.lastIndexOf("@");
      let lastDotPos = state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    setState({ ...state, errors: errors });
    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let is_valid = handleValidation();
    if (is_valid) {
      axios
        .post(`${base.BASE_URL}/web/contact/`, {
          name: state.name,
          subject: state.subject,
          message: state.message,
          phone: state.phone,
          email: state.email,
        })
        .then(function (response) {
          console.log(response.data.StatusCode ,'===response');
          if (response.data !== null && response.data !== undefined) {
            swal({
              title: response.data.title,
              text: response.data.message,
              icon: "success",
              button: true,
            });
            setState({
              ...state,
              name: "",
              message: "",
              phone: "",
              email: "",
              subject: "",
              errors: {},
            });
          }
        });
    }
  };
  return (
    <Container>
      <ContactContainer>
        <ContactUs>
          <ContactHeading>{t("Contact Us")}</ContactHeading>
          <ContactContent>
            {t("Looking For Help? Fill the form And Start A New Adventure.")}
          </ContactContent>

          <LocationHeading>{t("Locations")}</LocationHeading>
          <LocationMainContainer>
            <LocationContainer className="underline">
              <CountryContainer>
                <CountryLogo src="images/location/india.png" />
                <CountryText>INDIA</CountryText>
              </CountryContainer>
              <AddressContainer>
                <AddressLogo src="images/location/my-location.svg" />
                <AddressText>
                  {/* {t("Building No. UP 9/1230 B Unnikulam, Poonoor, Kerala")} */}
                  {t("Office Unit No. 11, Upper Basement Sahya IT SEZ Building, Cyberpark, Nellikkode, Kozhikode, Kerala 673016")}
                </AddressText>
                <LocationRedirect
                  href="https://www.google.com/maps/place/VIKN+CODES/@11.4370837,75.9002894,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba6660550823927:0x74a9aef5a3ce4519!8m2!3d11.4370837!4d75.9024781"
                  target="_blank"
                >
                  <LocationRedirectImage src="images/location/share.svg" />
                </LocationRedirect>
              </AddressContainer>

              <AddressContainer>
                <AddressLogo src="images/location/phone-alt.svg" />
                <AddressText>
                  <Bold>{t("India")}: </Bold>{" "}
                  <PhoneRedirect href="tel:+919577500400">
                    +91 95775 00400
                  </PhoneRedirect>
                </AddressText>
              </AddressContainer>
              <Underline></Underline>
            </LocationContainer>
            <LocationContainer>
              <CountryContainer>
                <CountryLogo src="images/location/saudi-arabia.png" />
                <CountryText>SAUDI ARABIA</CountryText>
              </CountryContainer>
              <AddressContainer>
                <AddressLogo src="images/location/my-location.svg" />
                <AddressText>Airport Road, Jizan</AddressText>
                <LocationRedirect
                  href="https://www.google.com/maps/place/Airport+Rd,+Jizan+Saudi+Arabia/@16.898098,42.5665457,17z/data=!3m1!4b1!4m9!1m2!2m1!1sAirport+Road,+Jazan+Saudi+Arabia!3m5!1s0x1607e2b879c26a7b:0x7061b7d63d849168!8m2!3d16.898098!4d42.5687344!15sCiBBaXJwb3J0IFJvYWQsIEphemFuIFNhdWRpIEFyYWJpYZIBBXJvdXRl"
                  target="_blank"
                >
                  <LocationRedirectImage src="images/location/share.svg" />
                </LocationRedirect>
              </AddressContainer>
              <AddressContainer>
                <AddressLogo src="images/location/phone-alt.svg" />
                <AddressText>
                  <Bold>KSA: </Bold>{" "}
                  <PhoneRedirect href="tel:+966548606939">
                    +9665 486 06939
                  </PhoneRedirect>
                  <br />
                  <Bold className="hidden">KSA: </Bold>
                  <PhoneRedirect href="tel:+966545982976">
                    +9665 459 82976
                  </PhoneRedirect>
                </AddressText>
              </AddressContainer>
              <AddressContainer>
                <AddressLogo src="images/location/phone-alt.svg" />
                <AddressText>
                  <Bold>Jeddah: </Bold>{" "}
                  <PhoneRedirect href="tel:+966543460168">
                   +9665 434 60168
                  </PhoneRedirect>
                </AddressText>
              </AddressContainer>
              <EnquiryContainer>
                <EnquiryHeading>{t("For Enquiries")}</EnquiryHeading>
                <EnquiryMail href="mailto:info@vikncodes.com">
                  info@vikncodes.com
                </EnquiryMail>
              </EnquiryContainer>
            </LocationContainer>
          </LocationMainContainer>
        </ContactUs>
        <SmallWrapper>
          <ContactUsContainer>
            <MessageContainer onChange={handleChange} autoComplete="off">
              <Title className="message">{t("Send us a Message")}</Title>
              <Description>
                {t(
                  "Just drop us a line, We will be glad to answer your Enquiries"
                )}
              </Description>
              <InputContainer>
                <InputGroup>
                  <InputField>
                    <CssTextField
                      value={state.name}
                      id="name"
                      name="name"
                      label={t("Name*")}
                      error={state.errors.name}
                      helperText={state.errors.name}
                    />
                  </InputField>
                  <InputField>
                    <CssTextField
                      value={state.phone}
                      id="phone"
                      name="phone"
                      label={t("Phone No*")}
                      error={state.errors.phone}
                      helperText={state.errors.phone}
                    />
                  </InputField>
                </InputGroup>
                <InputGroup>
                  <InputField>
                    <CssTextField
                      value={state.email}
                      id="email"
                      name="email"
                      label={t("Email Address*")}
                      error={state.errors.email}
                      helperText={state.errors.email}
                    />
                  </InputField>
                  <InputField>
                    <CssTextField
                      value={state.subject}
                      id="subject"
                      name="subject"
                      label={t("Subject*")}
                      error={state.errors.subject}
                      helperText={state.errors.subject}
                    />
                  </InputField>
                </InputGroup>
                <InputField>
                  <CssTextField
                    multiline
                    value={state.message}
                    id="message"
                    name="message"
                    label={t("Messages*")}
                    error={state.errors.message}
                    helperText={state.errors.message}
                  />
                </InputField>
              </InputContainer>
              <SubmitButton type="button" onClick={handleSubmit}>
                {t("Submit")}
              </SubmitButton>
            </MessageContainer>
          </ContactUsContainer>
        </SmallWrapper>
      </ContactContainer>
      <ContactMap>
        <div ref={ref} />
      </ContactMap>
    </Container>
  );
};
const ContactMap = styled.div`
  height: 350px;
  width: 80%;
  border-radius: 15px;
  z-index: 1;
  margin: 0 auto;
  margin-bottom: 50px;
  div {
    height: 100%;
    display: block;
    border-radius: 10px;
  }
  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5_hdpi.png"]
  {
    display: none;
  }
  img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"]
  {
    display: none;
  }
  .gmnoprint {
    display: none;
  }
  @media (max-width: 980px) {
    width: 100%;
    height: 500px;
    position: unset;
  }
  @media (max-width: 980px) {
    height: 300px;
  }
`;
const Container = styled.div``;
const ContactContainer = styled.main`
  /* background: #101010; */
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 7% 0 3%;
  display: flex;
  position: relative;
  z-index: 0;
  @media (max-width: 980px) {
    flex-direction: column;
    height: initial;
    position: unset;
    margin-bottom: 3%;
    padding-bottom: 0;
  }
  @media (max-width: 980px) {
    margin-top: 5%;
  }
  @media (max-width: 480px) {
    margin-top: 10%;
  }
`;

const ContactUs = styled.div`
  margin-right: 4%;
  width: 46%;
  @media (max-width: 980px) {
    width: 100%;
  }
`;
const ContactHeading = styled.div`
  font-family: inherit;
  text-align: left;
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 10px;
`;
const ContactContent = styled.div`
  width: 250px;
  color: #4e4e4e;
  line-height: 20px;
  margin-bottom: 20px;
`;
const LocationHeading = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
`;

// ------------------------
const SmallWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  @media (max-width: 980px) {
    width: 100%;
  }
`;
const InputField = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: ${({ language }) =>
    language === "ar" ? "row-reverse" : "row"};
  @media (max-width: 480px) {
    padding: 0 25px;
    flex-direction: column;
    align-items: ${({ language }) =>
      language === "ar" ? "flex-end" : "flex-start"};
    margin-bottom: 0;
    & .MuiTextField-root {
      width: 100%;
    }
    & .MuiInput-underline {
    }
  }
  & textarea {
    min-height: 75px;
    max-height: 75px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    display: block;
  }
`;
const InputContainer = styled.div`
  height: 270px;
`;
const SubmitButton = styled.button`
  border: 0;
  cursor: pointer;
  background: #1e7e60;
  padding: 10px 40px;
  color: #fff;
  border-radius: 20px;
  margin-top: 60px;
  outline: none;
  @media (max-width: 980px) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    margin-top: 65px;
  }
`;

const CssTextField = styled(TextField)`
  width: 100%;
  background: transparent;
  & placeholder: {
    color: #000;
  }
  & input: {
    color: #000;
  }
  & textarea: {
    color: #000;
  }
  // & label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated: {
  //   color: #fff;
  // };
  & label: {
    fontsize: 14px;
    color: #666666;
    background: transparent;
  }
  & label.mui-focused: {
    color: #666666;
  }
  & .muiinput-underline:before: {
    borderbottomcolor: #000;
    width: 100%;
  }
  & .muiinput-underline:after: {
    borderbottomcolor: #000;
    width: 100%;
  }
  & .muioutlinedinput-root: {
    & fieldset: {
      bordercolor: red;
    }
    &:hover fieldset: {
      bordercolor: yellow;
    }
    &.mui-focused fieldset: {
      bordercolor: #fff;
    }
  }
  & .MuiInput-underline:after {
    border-bottom: 2px solid #000;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #000;
  }
  & .MuiInputLabel-animated {
    font-size: 14px;
  }
`;
export default Contact;
const ContactUsContainer = styled.div`
  display: flex;
  border: 1px solid #ccc;
  border-radius: 15px;
`;
const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff;
  &.message {
    color: #2765bb;
  }
`;
const MessageContainer = styled.form`
  width: 100%;
  height: 500px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 30px;
  @media (max-width: 600px) {
    height: 540px;
  }
`;
const Description = styled.p`
  width: 215px;
  color: #666666;
  margin-bottom: 10px;
`;
const LocationContainer = styled.div`
  @media (max-width: 980px) {
    width: 48%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const CountryContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const CountryLogo = styled.img`
  width: 20px;
  margin-right: 10px;
`;
const CountryText = styled.p`
  font-weight: bold;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;
const AddressContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  position: relative;
  align-items: flex-start;
`;
const AddressLogo = styled.img`
  width: 20px;
  margin-right: 10px;
`;
const AddressText = styled.p`
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 10px;
  }
`;
const Bold = styled.span`
  font-weight: bold;
  &.hidden {
    visibility: hidden;
  }
`;
const LocationRedirectImage = styled.img`
  cursor: pointer;
  width: 16px;
  margin-left: 10px;
`;
const EnquiryContainer = styled.div``;
const EnquiryHeading = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
`;
const EnquiryMail = styled.a`
  color: #282828;
  text-decoration: underline;
`;
const PhoneRedirect = styled.a`
  color: #282828;
  font-weight: bold;
`;
const Underline = styled.div`
  width: 175px;
  height: 2px;
  margin: 20px 0;
  background: #e4e4e4;
  @media (max-width: 980px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: block;
  }
`;
const LocationRedirect = styled.a``;
const LocationMainContainer = styled.div`
  @media (max-width: 980px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  @media (max-width: 480px) {
    display: block;
  }
`;
