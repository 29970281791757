import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";


const Footer = (props) => {
  const [t, i18n] = useTranslation("common");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  let language = i18n.language;
  return (
    <FooterContainer>
      <InfoContainer>
        <ViknCodesQuote>
          <VikncodesLogo>
            <img src="/images/vikncodes.svg" alt="vikncodes" />
          </VikncodesLogo>
          <VikncodesQuoteContent language={language}>
            {t(
              "Our Mission is to help Every person And Organisation in the planet to Achieve Greatness."
            )}
          </VikncodesQuoteContent>
          <QuickLinkHeadings>
            <Link to="/portfolio">
              <span style={{color:'black'}}>{t("Portfolio")}</span>
            </Link>
            <Link to="/products">
              <span style={{color:'black'}}>{t("Products")}</span>
            </Link>
            <Link to="/about">
              <span style={{color:'black'}}>{t("About")}</span>
            </Link>
            <Link to="/career">
              <span style={{color:'black'}}>{t("Career")}</span>
            </Link>
            <Link to="/contact">
              <span style={{color:'black'}}>{t("Contact")}</span>
            </Link>
          </QuickLinkHeadings>
        </ViknCodesQuote>
        
        {/* <QuickLinks>
          <QuickLinkHeading language={language}>
            {t("Quick Links")}
          </QuickLinkHeading>
          <QuickLinkMenu language={language}>
            <Link to="/portfolio">
              <span>{t("Portfolio")}</span>
            </Link>
            <Link to="/products">
              <span>{t("Products")}</span>
            </Link>
            <Link to="/about">
              <span>{t("About")}</span>
            </Link>
            <Link to="/career">
              <span>{t("Career")}</span>
            </Link>
            <Link to="/contact">
              <span>{t("Contact")}</span>
            </Link>
          </QuickLinkMenu>
        </QuickLinks> */}


        <VikncodesAddress>
          <VikncodesAddressHeading language={language}>
            {t("Location")}
          </VikncodesAddressHeading>
          <AddressContainer>
            <VikncodesAddressContainer>
              {/* <VikncodesLocationLogo src="images/location/my-location.svg" /> */}

              <VikncodesAddressContent>
                <LocationName>
                  India 
                <VikncodesLocationPlaceLogo src="images/location/India.png" />
                </LocationName>
                {/* <VikncodesAddressText language={language}>
                  {t(
                    "Building No. UP 9/1230 B Unnikulam, Poonoor, Kerala 673574"
                  )}
                </VikncodesAddressText> */}
                <VikncodesAddressText language={language}>
                  {t(
                    "Govt. Cyberpark KSITIL Special Economic Zone"
                  )}
                </VikncodesAddressText>
                <VikncodesAddressText language={language}>
                  {t(" Nellikkode, Calicut Kerala - 673016")}
                </VikncodesAddressText>
                <VikncodesPhone>
                  <a href="tel:+919577500400">PH: +91 95775 00400</a>
                </VikncodesPhone>
                <VikncodesEmail>
                  <a href="mailto:info@vikncodes.com">
                    <span>{t("For Enquiries")}</span>: info@vikncodes.com
                  </a>
                </VikncodesEmail>
              </VikncodesAddressContent>
            </VikncodesAddressContainer>
            <VikncodesAddressContainer>
              {/* <VikncodesLocationLogo src="images/location/my-location.svg" /> */}

              <VikncodesAddressContent>
                <LocationName>
                  Saudi Arabia
                  <VikncodesLocationPlaceLogo src="images/location/SaudiArabia.svg.png" />
                </LocationName>
                <VikncodesAddressText language={language}>
                  {t("Airport Road, Jizan, Saudi Arabia")}
                </VikncodesAddressText>
                <VikncodesPhone>
                  <a href="tel:+966548606939">PH: +9665 486 06939</a>
                  <a href="tel:+966545982976">PH: +9665 459 82976</a>
                </VikncodesPhone>
              </VikncodesAddressContent>
            </VikncodesAddressContainer>

            <VikncodesAddressContainer>
              
              <VikncodesAddressContent>
                <LocationName>
                  Dubai 
                  <VikncodesLocationPlaceLogo src="images/location/UAE.png" />
                </LocationName>
                <VikncodesAddressText language={language}>
                  {t("Sharjah Media City, Sharjah, UAE")}
                </VikncodesAddressText>
                <VikncodesPhone>
                  <a href="tel:+966548606939">PH: +971 5095 00366</a>
                </VikncodesPhone>
              </VikncodesAddressContent>
            </VikncodesAddressContainer>

          </AddressContainer>
        </VikncodesAddress>
      </InfoContainer>
      <FooterBottom>
        <SocialMediaIcons>
          <Facebook>
            <a
              rel="noreferrer"
              href="https://www.facebook.com/vikncodesllp"
              target="_blank"
              // className="icon-facebook"
            >
              <i className="icon-facebook" aria-hidden="true"></i>
            </a>
          </Facebook>
          <Instagram>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/vikncodes/?hl=en"
              target="_blank"
              // className="icon-instagram"
            >
              <i className="icon-instagram" aria-hidden="true"></i>
            </a>
          </Instagram>
          <Twitter>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/vikncodes/?hl=en"
              target="_blank"
              // className="icon-twitter"
            >
              <i className="icon-twitter" aria-hidden="true"></i>
            </a>
          </Twitter>
        </SocialMediaIcons>
        <CopyRights language={language}>
          {t("All rights Reserved")} | {t("copyrights")} {currentYear} |{" "}
          <span onClick={() => {window.open('https://accounts.vikncodes.com/privacy-policy/:1', '_blank');}} style={{ cursor: 'pointer' }}> &nbsp; {t("Privacy Policy")} &nbsp;</span>
        | <span onClick={() => {window.open('https://accounts.vikncodes.com/privacy-policy/:2', '_blank');}} style={{ cursor: 'pointer' }}> &nbsp; {t("Terms and Conditions")} </span>
        </CopyRights>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  border-top: 1px solid #ccc;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4% 0;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const ViknCodesQuote = styled.div`
  width: 32%;
  @media all and (max-width: 968px) {
    width: 38%;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    margin-bottom: 3%;
  }
`;
const VikncodesLogo = styled.div`
  margin-bottom: 4%;
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
  }
`;
const VikncodesQuoteContent = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  line-height: 1.5em;
  @media all and (max-width: 768px) {
    text-align: center;
  }
`;
const QuickLinks = styled.div`
  width: 25%;
  padding: 0% 2%;
  @media all and (max-width: 768px) {
    display: none;
    width: 100%;
    margin-bottom: 3%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    padding: 0;
  }
`;
const QuickLinkHeading = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8%;
  @media all and (max-width: 768px) {
    margin-bottom: 2%;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 4%;
  }
`;
const QuickLinkMenu = styled.div`
  a span {
    font-family: ${({ language }) =>
      language === "ar" ? "cairoregular" : "inherit"};
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  span {
    color: #000;
  }
  a {
    margin-bottom: 10%;
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      margin-bottom: 4%;
    }
    a span {
      letter-spacing: 5px;
    }
  }
`;
const VikncodesAddress = styled.div`
  width: 55%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const VikncodesAddressHeading = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4%;
  @media all and (max-width: 768px) {
    text-align: center;
    margin-bottom: 2%;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 4%;
  }
`;
const VikncodesAddressContent = styled.div`
  @media all and (max-width: 768px) {
    text-align: center;
  }
`;
// const VikncodesAddressText = styled.div`
//   font-family: ${({ language }) =>
//     language === "ar" ? "cairoregular" : "inherit"};
//   margin-bottom: 4%;
//   @media all and (max-width: 768px) {
//     margin-bottom: 2%;
//   }
// `;
const VikncodesAddressText = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  margin-bottom: 4%;
  @media all and (max-width: 768px) {
    margin-bottom: 2%;
  }
`;
const VikncodesPhone = styled.div`
  margin-bottom: 4%;
  a {
    color: #000;
    font-family: "Roboto", sans-serif;
    display: block;
    margin-bottom: 5px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 2%;
  }
`;
const VikncodesEmail = styled.div`
  a {
    color: #000;
  }
  span {
    font-weight: bold;
  }
`;
const SocialMediaIcons = styled.div`
  display: flex;
  font-size: 25px;
  justify-content: space-between;
  width: 13%;
  color: #c2351a;
  a {
    color: #c2351a;
  }
  @media all and (max-width: 1200px) {
    width: 15%;
  }
  @media all and (max-width: 1200px) {
    width: 20%;
  }
  @media all and (max-width: 768px) {
    width: 22%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 2%;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const Facebook = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    width: 35px;
    height: 35px;
  }
`;
const Instagram = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 640px) {
    margin: 0 2%;
  }
  @media all and (max-width: 480px) {
    width: 35px;
    height: 35px;
  }
`;
const Twitter = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    width: 35px;
    height: 35px;
  }
`;
const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2%;
  @media all and (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const CopyRights = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  display: flex;
  align-items: flex-end;
  @media all and (max-width: 640px) {
    justify-content: center;
  }
  @media all and (max-width: 480px) {
    text-align: center;
    line-height: 1.2em;
  }
`;

const VikncodesAddressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border: 1px solid transparent;
  @media all and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;
const VikncodesLocationLogo = styled.img`
  width: 20px;
  margin-right: 10px;
`;
const AddressContainer = styled.div`
  display: flex;
  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const LocationName = styled.div`
  font-weight: bold;
  // font-size:15px;
  padding-bottom:8px;
  display:flex;
  justify-content:start;
  align-items:center;
  @media all and (max-width: 768px) {
  justify-content:center;
  }
`
const VikncodesLocationPlaceLogo = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  border-radius:50%;
  
`;
const QuickLinkHeadings = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  // border:1px solid red;
  display:flex;
  margin-top:40px;
  justify-content:space-between;
  @media all and (max-width: 768px) {
   margin-top:25px;
  justify-content:space-around;
  }
`;
export default Footer;
